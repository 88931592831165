<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('COUNTRYADMIN.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('COUNTRYADMIN.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="12">
      <vs-card v-if="editMode" >
        <div id="editCountryCard" class="vs-con-loading__container">
          <h3 v-if="!newCountry">{{$t('COUNTRYADMIN.EDIT.HEADER')}}</h3>
          <h3 v-if="newCountry">{{$t('COUNTRYADMIN.NEW.HEADER')}}</h3>
          <div class="default-input d-flex align-items-center">
            <vs-input :label="$t('COUNTRYADMIN.EDIT.LABEL.NAME')"  class="inputx" :placeholder="$t('COUNTRYADMIN.EDIT.LABEL.NAME')"  v-model="selected.name" />
            <vs-input :label="$t('COUNTRYADMIN.EDIT.LABEL.KUERZEL')"  class="inputx ml-3" :placeholder="$t('COUNTRYADMIN.EDIT.LABEL.KUERZEL')"  v-model="selected.kuerzel" />
            <vs-select 
              :label="$t('COUNTRYADMIN.EDIT.LABEL.SPRACHE')"
              class="ml-3 "
              v-model="selected.language_fk">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in languages" />
            </vs-select>
            <vs-select :label="$t('COUNTRYADMIN.EDIT.LABEL.WAEHRUNG')" v-model="selected.currency_fk" class="ml-3 ">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in currencies" />
            </vs-select>
            <vs-checkbox v-model="selected.anonym">{{$t('COUNTRYADMIN.EDIT.LABEL.ANONYM')}}</vs-checkbox>
          </div>
          <SaveCancel class="mt-3" :SaveAllowed = !countryDataChanged v-on:SaveClicked="Save" v-on:CancelClicked="Cancel"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddCountry">{{$t('COUNTRYADMIN.ADD')}}</vs-button>
            </div> 
            <div id="countryadmin_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="countries"
                :noDataText="$t('COUNTRYADMIN.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat
                v-model="selectedCountry">

                <template slot="header">
                  <h3>
                    {{$t('COUNTRYADMIN.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="name">
                    {{$t('COUNTRYADMIN.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="kuerzel">
                    {{$t('COUNTRYADMIN.TABLE.COL2')}}
                  </vs-th> 
                  <vs-th sort-key="language_fk">
                    {{$t('COUNTRYADMIN.TABLE.COL3')}}
                  </vs-th> 
                  <vs-th sort-key="currency_fk">
                    {{$t('COUNTRYADMIN.TABLE.COL4')}}
                  </vs-th>                                                   
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.name">
                      {{tr.name}}
                    </vs-td>      
                    <vs-td :data="tr.kuerzel">
                      {{tr.kuerzel}}
                    </vs-td> 
                    <vs-td :data="tr.language_fk">
                      {{GetNameOfLanguage(tr.language_fk)}}
                    </vs-td> 
                    <vs-td :data="tr.currency_fk">
                      {{GetNameOfCurrency(tr.currency_fk)}}
                    </vs-td>                                                           
                    <vs-td>
                      <vs-button  @click="EditCountry(tr.id)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteContry(tr.id,tr.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
      <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="7" vs-sm="6" vs-xs="12">
      <vs-card v-if="editModeMwst" >
        <div id="editMwstCard" class="vs-con-loading__container">
          <h3 v-if="!newMwst">{{$t('COUNTRYADMIN.EDITMWST.HEADER')}}</h3>
          <h3 v-if="newMwst">{{$t('COUNTRYADMIN.EDITMWST.HEADERNEW')}}</h3>
          <div class="default-input d-flex align-items-center mt-3">
            <vs-input :label="$t('COUNTRYADMIN.EDITMWST.LABEL.BEZ')"  class="inputx" :placeholder="$t('COUNTRYADMIN.EDITMWST.LABEL.BEZ')"  v-model="selectedMwst.bez" :danger="!mwstBezValid" val-icon-danger="clear" />
          </div>
          <vs-divider icon="euro" position="center"></vs-divider>
          <div class="default-input d-flex align-items-center mt-3">
            <vs-input-number id="mwst_value" v-model="selectedMwst.value" :label="$t('COUNTRYADMIN.EDITMWST.LABEL.VAL')" class="inputx" :class="!mwstValueValid ? 'input_number_invalid':''" />
            <vs-checkbox v-model="selectedMwst.isDefault"  class="inputx ml-3" >{{$t('COUNTRYADMIN.EDITMWST.LABEL.DEFAULT')}}</vs-checkbox>
          </div>
          <vs-divider icon="today" position="center"></vs-divider>
          <div class="default-input d-flex align-items-center mt-3">
            <vs-checkbox :disabled="selectedMwst.isDefault" v-model="selectedMwst.useDateRange" class="inputx" >{{$t('COUNTRYADMIN.EDITMWST.LABEL.USERANGE')}}</vs-checkbox>
          </div>
          <div class="default-input d-flex align-items-center mt-3" v-if="selectedMwst.useDateRange">
            <DatePicker class="" :Label="$t('COUNTRYADMIN.EDITMWST.LABEL.FROM')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('COUNTRYADMIN.EDITMWST.POPUP.TITLE.FROM')" v-model="selectedMwst.valid_from"></DatePicker>
            <DatePicker class="" :Label="$t('COUNTRYADMIN.EDITMWST.LABEL.TO')" :OkCancelLabel="{ok:$t('COM.OK'),cancel:$t('COM.CANCEL')}" :PopUpTitel="$t('COUNTRYADMIN.EDITMWST.POPUP.TITLE.TO')" v-model="selectedMwst.valid_to"></DatePicker>
          </div>          
          <SaveCancel class="mt-3" :SaveAllowed = !mwstDataChanged v-on:SaveClicked="SaveMwst" v-on:CancelClicked="CancelMwst"></SaveCancel>   
        </div>
      </vs-card>
      <vs-card v-if="showMwst">
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="AddMwst">{{$t('COUNTRYADMIN.MWST.ADD')}}</vs-button>
            </div> 
            <div id="countryadminmwst_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="mwsts"
                :noDataText="$t('COUNTRYADMIN.MWST.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('COUNTRYADMIN.MWST_TABLE.HEADER')}} {{this.selectedCountry.name}}
                  </h3>
                </template>
                <template slot="thead">                               
                  <vs-th sort-key="bez">
                    {{$t('COUNTRYADMIN.MWST_TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="value">
                    {{$t('COUNTRYADMIN.MWST_TABLE.COL2')}}
                  </vs-th>  
                  <vs-th sort-key="isDefault">
                    {{$t('COUNTRYADMIN.MWST_TABLE.COL3')}}
                  </vs-th> 
                  <vs-th sort-key="valid_from">
                    {{$t('COUNTRYADMIN.MWST_TABLE.COL4')}}
                  </vs-th> 
                  <vs-th sort-key="valid_to">
                    {{$t('COUNTRYADMIN.MWST_TABLE.COL5')}}
                  </vs-th>                                                                                       
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr  :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.bez">
                      {{tr.bez}}
                    </vs-td>      
                    <vs-td :data="tr.value">
                      {{tr.value}}
                    </vs-td> 
                    <vs-td :data="tr.isDefault">
                      <vs-checkbox v-model="tr.isDefault" disabled="true" ></vs-checkbox>
                    </vs-td>  
                    <vs-td :data="tr.valid_from">
                      {{DateToString(tr.valid_from,false)}}  
                    </vs-td>  
                    <vs-td :data="tr.valid_to">
                      {{DateToString(tr.valid_to,false)}}  
                    </vs-td>                             
                    <vs-td>
                      <vs-button  @click="EditMwst(tr.id)" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                      <vs-button  @click="OpenConfirmDeleteMwst(tr.id,tr.bez,tr.country_fk)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import SaveCancel from './components/SaveCancel';
import helperMethods from '../helper/staticFuncHelper';
import DatePicker from './components/DatePicker';

export default {
  name: "CountryAdmin",
  components: {
    SaveCancel,
    DatePicker
  },
    data: function (){
      return {
      countries:[],
      selected:[],
      selectedCountry:[],
      selectedOld:"",
      editMode:false,
      newCountry:false,
      newMwst:false,
      mwsts:[],
      editModeMwst:false,
      selectedMwst:[],
      selectedMwstOld:"",
      showMwst:false,
      mwstBezValid:true,
      mwstValueValid:true
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.country.countries.data != null)
      {
        data = this.$store.state.country.countries.data
      }
      return data;
    },
    getMwstData(){
      var data = [];
      if(this.$store.state.country.mwst.data != null)
      {
        data = this.$store.state.country.mwst.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.country.countries.status) != 'undefined')
        loading = this.$store.state.country.countries.status.loading;
      return loading;
    },
    countryDataChanged(){
        return JSON.stringify(this.selected) != this.selectedOld;
    },
    mwstDataChanged(){
        return JSON.stringify(this.selectedMwst) != this.selectedMwstOld;
    },
    isDefaultChecked(){
      var retVal = false;
      if(typeof(this.selectedMwst) != 'undefined')
      {
        retVal = this.selectedMwst.isDefault;
      }
      return retVal;
    },
    languages: function(){
      return this.GetListOfLanguagesForSelect();
    },
    currencies(){    
      return this.GetListOfCurrenciesForSelect(false);
    },
  },
  watch: {
      getTableData(value) {
          this.countries = value;
      },
      getMwstData(value){
        this.mwsts = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#countryadmin_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#countryadmin_table'});
        }
      },
      selectedCountry(value){
        if(typeof(value.id)!= 'undefined')
        {
          if(value.id > 0)
          {
            this.LoadMwstForCountry(value.id);
            this.showMwst = true;
          }
          else
          {
            this.showMwst = false;
          }
        }
      },
      isDefaultChecked(value)
      {
        if(value)
        {
          this.selectedMwst.valid_from = '';
          this.selectedMwst.valid_to = '';
          this.selectedMwst.useDateRange = false;
        }
      }
    },
  methods: {    
      ...helperMethods,
      LoadData: function ()
      {
          this.$store.dispatch('country/getCountries'); 
      },
      LoadMwstForCountry(country_fk)
      {
        this.$store.dispatch('country/getMwstForCountry',country_fk); 
      },
      ValidateMwst()
      {
        var valid = false;
        // Wenn man in das numeric dings händisch eintippt kommt es als text an... umwandeln!
        if(typeof(this.selectedMwst) != 'undefined')
        {
          this.selectedMwst.value = Number(this.selectedMwst.value);

          this.mwstValueValid = this.selectedMwst.value > 0;
          this.mwstBezValid = this.selectedMwst.bez != '';
        }

        valid = (this.mwstValueValid && this.mwstBezValid);

        if(!valid)
        {
          this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('COUNTRYADMIN.MWST.INVALID')});
        }
        return valid;
      },
      EditCountry(id){
        if(this.$store.state.country.countries.data != null)
        {
          for(var i = 0; i < this.$store.state.country.countries.data.length; i++)
          {
            if(this.$store.state.country.countries.data[i].id==id)
            {
              this.selectedOld = JSON.stringify(this.$store.state.country.countries.data[i]);
              this.selected = JSON.parse(this.selectedOld);
              this.editMode = true;
              break;
            }
          }
        }
      },
      EditMwst(id)
      {
        if(this.$store.state.country.mwst.data != null)
        {
          for(var i = 0; i < this.$store.state.country.mwst.data.length; i++)
          {
            if(this.$store.state.country.mwst.data[i].id==id)
            {
              this.selectedMwstOld = JSON.stringify(this.$store.state.country.mwst.data[i]);
              this.selectedMwst = JSON.parse(this.selectedMwstOld);
              this.editModeMwst = true;
              break;
            }
          }
        }
      },
      AddCountry(){
        this.selected={id:0,name:"",kuerzel:"",language_fk:-1,currency_fk:-1};
        this.selectedOld = JSON.stringify(this.selected);
        this.editMode = true;
        this.newCountry = true;
      },
      AddMwst(){
        this.selectedMwst={id:0,bez:"",value:0,isDefault:false,valid_from:"",valid_to:"",country_fk:this.selectedCountry.id,useDateRange:false};
        this.selectedMwstOld = JSON.stringify(this.selectedMwst);
        this.editModeMwst = true;
        this.newMwst = true;
      },
      SaveMwst(){

        if(this.ValidateMwst())
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#editMwstCard'});

          var name = this.selectedMwst.bez;
          var id = this.selectedCountry.id;
              this.$store.dispatch('country/saveMwst', this.selectedMwst)
                .then(response => {
                  if(response.success == true)
                  {
                    this.LoadMwstForCountry(id);
                    this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COUNTRYADMIN.SUCCESS.SAVE').replace("*1*",name)});
                    this.editModeMwst = false;
                  }
                  else
                  {
                    this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COUNTRYADMIN.ERROR.SAVE').replace("*1*",name)});
                  }
                  this.$store.dispatch('alertqueue/hideLoader', {'id':'#editMwstCard'});
                }, 
                error =>{
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COUNTRYADMIN.ERROR.SAVE').replace("*1*",name)+' '+error});
                  this.$store.dispatch('alertqueue/hideLoader', {'id':'#editMwstCard'});
                });  
        } 
      },
      Save(){
        this.$store.dispatch('alertqueue/showLoader', {'id':'#editCountryCard'});

        var name = this.selected.name;
            this.$store.dispatch('country/saveCountry', this.selected)
              .then(response => {
                if(response.success == true)
                {
                  this.LoadData();
                  this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COUNTRYADMIN.SUCCESS.SAVE').replace("*1*",name)});
                  this.editMode = false;
                }
                else
                {
                  this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COUNTRYADMIN.ERROR.SAVE').replace("*1*",name)});
                }
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editCountryCard'});
              }, 
              error =>{
                this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COUNTRYADMIN.ERROR.SAVE').replace("*1*",name)+' '+error});
                this.$store.dispatch('alertqueue/hideLoader', {'id':'#editCountryCard'});
              });        
      },
      Cancel(){
        this.$data.selected = [];
        this.editMode = false;
        this.newCountry = false;
      },
      CancelMwst(){
        this.$data.selectedMwst = [];
        this.editModeMwst = false;
        this.newMwst = false;
      },   
      OpenConfirmDeleteMwst(id,bez,country_fk)
      {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('COUNTRYADMIN.MWST_QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteMwstRequest,
          parameters:{"id": id,"bez": bez,"country_fk":country_fk}
        });
      },  
      OpenConfirmDeleteContry(id,name) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('COUNTRYADMIN.QUESTION.DELETE').replace("*1*",name),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"name": name}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('country/deleteCountry', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COUNTRYADMIN.SUCCESS.DELETE').replace("*1*",parameters.name)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('COUNTRYADMIN.WARNING.DELETE').replace("*1*",parameters.name)}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COUNTRYADMIN.ERROR.DELETE').replace("*1*",error)});  
        });
      },
      SendDeleteMwstRequest: function(parameters)
      {
        this.$store.dispatch('country/deleteMwst', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COUNTRYADMIN.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
          }
          else
          {
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':this.$t('COUNTRYADMIN.WARNING.DELETE').replace("*1*",parameters.bez)}); 
          }
          this.LoadMwstForCountry(parameters.country_fk);
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COUNTRYADMIN.ERROR.DELETE').replace("*1*",error)});  
        });
      }
  }
};

</script>